// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
//
//
// export default defineNuxtPlugin((nuxtApp) => {
//
//     nuxtApp.vueApp.use(TawkMessengerVue, {
//
//         propertyId : '65efe41e9131ed19d978846f',
//
//         widgetId : '1hooh66c9'
//
//     });
//
// });